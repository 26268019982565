import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchEmployees,
  selectEmployee,
  toggleEmployeeStatusThunk,
  uploadCSVThunk,
  resetProcessedCsvData,
} from '../slices/employeeSlice';
import { fetchPlansThunk } from '../slices/planSlice';
import { processFile } from '../utils/csvUtil';
import {
  CircularProgress,
  TableBody,
  TableHead,
  Tooltip,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AddEmployeeDialog from './AddEmployeeDialog';
import EditEmployeeDialog from './EditEmployeeDialog';
import ComparisonDialog from './ComparisonDialog';
import { CompanyName, StyledPaper, StyledSearchBar } from '../styles/styledComponents';
import { EmployeeRow, HeaderCell, HeaderTableRow, StyledTable, StyledTableCell, StyledTableContainer } from '../styles/tableStyles';
import { AddEmployeeButton, DisableButton, EditButton, EnableButton, UploadCSVButton } from '../styles/buttonComponents';
import { ActionContainer, NavbarContainer } from '../styles/containerStyles';
import { selectCurrentAdmin } from '../selectors';
import { AuthContext } from '../utils/AuthProvider';
import { showErrorToast, showSuccessToast } from '../utils/toastUtil';

const SortableHeaderCell = ({ children, sortOrder, column, toggleSort }) => (
  <HeaderCell onClick={() => toggleSort(column)} style={{ cursor: 'pointer' }}>
    {children} {sortOrder.column === column ? (sortOrder.direction === 'asc' ? '↑' : '↓') : null}
  </HeaderCell>
);

SortableHeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
  sortOrder: PropTypes.shape({
    column: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired
  }).isRequired,
  column: PropTypes.string.isRequired,
  toggleSort: PropTypes.func.isRequired
};

function Employee() {
  const { id: companyId } = useParams();
  const dispatch = useDispatch();
  const { getIdToken } = useContext(AuthContext);
  const fileRef = useRef(null);

  // Data State
  const employees = useSelector((state) => state.employee?.employees || []);
  const employeeChanges = useSelector((state) => state.employee.employeeChanges) || [];
  const currentAdmin = useSelector(selectCurrentAdmin);
  const plans = useSelector((state) => state.plan.plans);
  const selectedEmployee = useSelector((state) => state.employee.selectedEmployee);
  const companies = currentAdmin?.companies || [];
  const companyName = companies.find(c => c.companyId === parseInt(companyId))?.name || '';
  const isLoading = useSelector(state => state.employee.isLoading);

  // Table State
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOrder, setSortOrder] = useState({ column: 'status', direction: 'asc' });
  const [showInactive, setShowInactive] = useState(false);
  const [employeeStats, setEmployeeStats] = useState({ active: 0, inactive: 0, total: 0 });

  // Dialog State
  const [isAddEmployeeDialogOpen, setIsAddEmployeeDialogOpen] = useState(false);
  const [isEditEmployeeDialogOpen, setIsEditEmployeeDialogOpen] = useState(false);
  const [isComparisonDialogOpen, setIsComparisonDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchEmployees(companyId));
    dispatch(fetchPlansThunk());
  }, [companyId, dispatch]);

  useEffect(() => {
    const stats = employees.reduce((acc, emp) => {
      emp.isActive ? acc.active++ : acc.inactive++;
      acc.total++;
      return acc;
    }, { active: 0, inactive: 0, total: 0 });
    setEmployeeStats(stats);
  }, [employees]);

  const toggleSort = (column) => {
    setSortOrder(prevOrder => ({
      column,
      direction: prevOrder.column === column && prevOrder.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const filteredEmployees = useMemo(() => {
    const searchTerm = searchQuery?.toLowerCase();
    return [...employees]
      .filter(employee =>
        (showInactive || employee.isActive) &&
        ((typeof employee.firstName === 'string' && employee.firstName.toLowerCase().includes(searchTerm)) ||
          (typeof employee.lastName === 'string' && employee.lastName.toLowerCase().includes(searchTerm)) ||
          (typeof employee.email === 'string' && employee.email.toLowerCase().includes(searchTerm)))
      )
      .sort((a, b) => {
        const sortOrderMultiplier = sortOrder.direction === 'asc' ? 1 : -1;
        const safeCompare = (valueA, valueB) => {
          if (valueA == null && valueB == null) return 0;
          if (valueA == null) return -1;
          if (valueB == null) return 1;
          return valueA.toString().localeCompare(valueB.toString());
        };

        switch (sortOrder.column) {
        case 'status':
          return (b.isActive - a.isActive) * sortOrderMultiplier;
        case 'plan':
          return safeCompare(a.planName, b.planName) * sortOrderMultiplier;
        case 'firstName':
          return safeCompare(a.firstName, b.firstName) * sortOrderMultiplier;
        case 'lastName':
          return safeCompare(a.lastName, b.lastName) * sortOrderMultiplier;
        case 'email':
          return safeCompare(a.email, b.email) * sortOrderMultiplier;
        default:
          return 0;
        }
      });
  }, [employees, searchQuery, sortOrder, showInactive]);

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const editEmployee = (employee) => {
    dispatch(selectEmployee(employee));
    setIsEditEmployeeDialogOpen(true);
  };

  const toggleEmployeeStatus = (employee) => {
    dispatch(toggleEmployeeStatusThunk(employee.documentId))
      .then((value) => {
        console.log(value);
        showSuccessToast('Employee toggle successful');
      })
      .catch(() => {
        showErrorToast('Failed to toggle employee status!');
      });
  };

  const handleUserDialogClose = (updatedUser) => {
    if (updatedUser.documentId) {
      showSuccessToast(`${updatedUser.firstName} ${updatedUser.lastName} was added successfully!`);
    }
    setIsEditEmployeeDialogOpen(false);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    e.target.value = null;

    const token = await getIdToken();
    if (!token) {
      showErrorToast('Authentication error: Unable to retrieve token');
      return;
    }

    processFile(
      file,
      employees,
      companies,
      plans,
      companyId,
      async (parsedData) => {
        try {
          const action = await dispatch(uploadCSVThunk({ companyId, parsedData, token }));
          if (uploadCSVThunk.fulfilled.match(action)) {
            setIsComparisonDialogOpen(true);
          } else {
            showErrorToast('Error processing CSV!', action.payload || action.error.message);
          }
        } catch (error) {
          showErrorToast('Error processing CSV!', error.message);
        }
      },
      (error) => {
        showErrorToast('Error processing CSV!', error.message);
      }
    );
  };

  const handleComparisonDialogClose = () => {
    dispatch(resetProcessedCsvData());
    setIsComparisonDialogOpen(false);
    dispatch(fetchEmployees(companyId));
  };

  return (
    <StyledPaper>
      <NavbarContainer>
        <Box display="flex" alignItems="center">
          <Tooltip 
            title="Back to Companies"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.white',
                  color: 'rgba(0, 0, 0, 0.87)',
                  '& .MuiTooltip-arrow': {
                    color: 'common.white',
                  },
                },
              },
            }}
          >
            <IconButton component={Link} to="/companies">
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <CompanyName>{`${companyName} (${companyId})`}</CompanyName>
        </Box>
        <ActionContainer>
          <StyledSearchBar onChange={(e) => handleSearch(e.target.value)} />
          <AddEmployeeButton variant="contained" onClick={() => setIsAddEmployeeDialogOpen(true)}>
            Add Employee
          </AddEmployeeButton>
          <input type="file" ref={fileRef} style={{ display: 'none' }} onChange={handleFileChange} accept=".csv,.xlsx,.xls" />
          <Tooltip 
            title="Upload CSV or Excel file"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'common.white',
                  color: 'rgba(0, 0, 0, 0.87)',
                  '& .MuiTooltip-arrow': {
                    color: 'common.white',
                  },
                },
              },
            }}
          >
            <UploadCSVButton variant="contained" onClick={() => fileRef.current.click()}>
              <CloudUploadIcon />
            </UploadCSVButton>
          </Tooltip>
        </ActionContainer>
      </NavbarContainer>
  
      <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
        <Typography variant="h6" style={{ color: '#ffffff' }}>
          Employees: {employeeStats.total} (Active: {employeeStats.active}, Inactive: {employeeStats.inactive})
        </Typography>
        <Tooltip 
          title={showInactive ? 'Hide inactive employees' : 'Show inactive employees'}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'common.white',
                color: 'rgba(0, 0, 0, 0.87)',
                '& .MuiTooltip-arrow': {
                  color: 'common.white',
                },
              },
            },
          }}
        >
          <IconButton onClick={() => setShowInactive(!showInactive)}>
            {showInactive ? <PersonIcon /> : <LockIcon />}
          </IconButton>
        </Tooltip>
      </Box>
  
      <StyledTableContainer>
        {isLoading && (
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            backgroundColor: '#5d23944b'
          }}>
            <CircularProgress size={100} />
          </div>
        )}
        <StyledTable>
          <TableHead>
            <HeaderTableRow>
              <SortableHeaderCell sortOrder={sortOrder} column="firstName" toggleSort={toggleSort}>First Name</SortableHeaderCell>
              <SortableHeaderCell sortOrder={sortOrder} column="lastName" toggleSort={toggleSort}>Last Name</SortableHeaderCell>
              <SortableHeaderCell sortOrder={sortOrder} column="email" toggleSort={toggleSort}>Email</SortableHeaderCell>
              <SortableHeaderCell sortOrder={sortOrder} column="planName" toggleSort={toggleSort}>Plan</SortableHeaderCell>
              <SortableHeaderCell sortOrder={sortOrder} column="status" toggleSort={toggleSort}>Status</SortableHeaderCell>
              <HeaderCell>Edit</HeaderCell>
            </HeaderTableRow>
          </TableHead>
          <TableBody>
            {filteredEmployees.map((employee) => (
              <EmployeeRow key={employee.documentId} isActive={employee.isActive}>
                <StyledTableCell>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {employee.isActive ? (
                      <>
                        <PersonIcon style={{ marginRight: '8px', color: '#3f51b5' }} />
                        {employee.firstName}
                      </>
                    ) : (
                      <Tooltip 
                        title="Employee is disabled"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: 'common.white',
                              color: 'rgba(0, 0, 0, 0.87)',
                              '& .MuiTooltip-arrow': {
                                color: 'common.white',
                              },
                            },
                          },
                        }}
                      >
                        <span>
                          <LockIcon color="error" style={{ marginRight: '8px' }} />
                          {employee.firstName}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  {employee.isActive ? (
                    employee.lastName
                  ) : (
                    <Tooltip 
                      title="Employee is disabled"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: 'common.white',
                            color: 'rgba(0, 0, 0, 0.87)',
                            '& .MuiTooltip-arrow': {
                              color: 'common.white',
                            },
                          },
                        },
                      }}
                    >
                      <span>{employee.lastName}</span>
                    </Tooltip>
                  )}
                </StyledTableCell>
                <StyledTableCell>{employee.email}</StyledTableCell>
                <StyledTableCell>{employee.planName}</StyledTableCell>
                <StyledTableCell>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        {employee.isActive ? (
                          <DisableButton variant="contained" onClick={() => toggleEmployeeStatus(employee)}>Disable</DisableButton>
                        ) : (
                          <EnableButton variant="contained" onClick={() => toggleEmployeeStatus(employee)}>Enable</EnableButton>
                        )}
                      </>
                    )}
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <EditButton variant="contained" onClick={() => editEmployee(employee)}>Edit</EditButton>
                    )}
                  </div>
                </StyledTableCell>
              </EmployeeRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      <AddEmployeeDialog 
        open={isAddEmployeeDialogOpen} 
        onClose={() => setIsAddEmployeeDialogOpen(false)} 
        companyId={companyId} 
        companies={companies} 
        plans={plans} 
        employees={employees} 
      />
      {selectedEmployee && (
        <EditEmployeeDialog 
          open={isEditEmployeeDialogOpen} 
          onClose={(arg) => handleUserDialogClose(arg)} 
          companyId={companyId} 
          employee={selectedEmployee} 
          companies={companies} 
          plans={plans} 
        />
      )}
      <ComparisonDialog 
        open={isComparisonDialogOpen} 
        onClose={handleComparisonDialogClose}  
        employeeChanges={employeeChanges} 
        companyId={companyId} 
        companies={companies} 
        plans={plans} 
      />
    </StyledPaper>
  );
}

export default Employee;