import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { addCompanyThunk, updateCompanyThunk, fetchCompanies } from '../slices/companySlice';

function ManageCompaniesDialog({ open, onClose, company }) {
  const dispatch = useDispatch();
  const [companyData, setCompanyData] = useState({
    name: '',
    phoneNumber: '',
    preauthPhoneNumber: '',
    costPerEE: ''
  });

  const [mode, setMode] = useState('add');
  const [selectedCompanyId, setSelectedCompanyId] = useState('');

  const companies = useSelector(state => state.company.companies);
  const isLoading = useSelector(state => state.company.isUpdatingCompany);

  useEffect(() => {
    if (open) {
      dispatch(fetchCompanies());
    }
  }, [open, dispatch]);

  const handleModeChange = (e) => {
    const newMode = e.target.value;
    setMode(newMode);
    
    if (newMode === 'add') {
      setCompanyData({ name: '', phoneNumber: '', preauthPhoneNumber: '', costPerEE: '' });
      setSelectedCompanyId('');
    }
  };

  const handleCompanySelection = (e) => {
    const selectedCompanyId = e.target.value;
    const selectedCompany = companies.find(comp => comp.companyId === selectedCompanyId);

    setSelectedCompanyId(selectedCompanyId);
    if (selectedCompany) {
      setCompanyData({
        name: selectedCompany.name,
        phoneNumber: selectedCompany.phoneNumber,
        preauthPhoneNumber: selectedCompany.preauthPhoneNumber,
        costPerEE: formatCostPerEE(selectedCompany.costPerEmployee) // Format costPerEE for input
      });
    }
  };

  const formatCostPerEE = (costPerEmployee) => {
    if (typeof costPerEmployee === 'number') {
      return costPerEmployee.toString();
    } else if (typeof costPerEmployee === 'object') {
      return Object.values(costPerEmployee).join('/');
    }
    return '';
  };

  const parseCostPerEE = (costPerEE) => {
    if (costPerEE.includes('/')) {
      const [employeeOnly, employeeSpouse, employeeChildren, employeeFamily] = costPerEE.split('/');
      return {
        'Employee Only': parseFloat(employeeOnly),
        'Employee + Spouse': parseFloat(employeeSpouse),
        'Employee + Child(ren)': parseFloat(employeeChildren),
        'Employee + Family': parseFloat(employeeFamily)
      };
    }
    return parseFloat(costPerEE);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    const parsedCostPerEE = parseCostPerEE(companyData.costPerEE);
    const updatedCompanyData = { ...companyData, costPerEmployee: parsedCostPerEE };

    if (mode === 'edit' && selectedCompanyId) {
      dispatch(updateCompanyThunk({ companyId: selectedCompanyId, ...updatedCompanyData }));
    } else {
      dispatch(addCompanyThunk(updatedCompanyData));
    }
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Manage Companies</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset">
          <FormLabel component="legend">Mode</FormLabel>
          <RadioGroup
            row
            aria-label="mode"
            name="mode"
            value={mode}
            onChange={handleModeChange}
          >
            <FormControlLabel value="add" control={<Radio />} label="Add Company" />
            <FormControlLabel value="edit" control={<Radio />} label="Edit Company" />
          </RadioGroup>
        </FormControl>

        {mode === 'edit' && (
          <Select
            value={selectedCompanyId}
            onChange={handleCompanySelection}
            fullWidth
            displayEmpty
            margin="dense"
          >
            <MenuItem disabled value="">
              <em>Select a company to edit</em>
            </MenuItem>
            {companies.map(comp => (
              <MenuItem key={comp.companyId} value={comp.companyId}>
                {comp.name}
              </MenuItem>
            ))}
          </Select>
        )}

        <TextField
          margin="dense"
          name="name"
          label="Company Name"
          type="text"
          fullWidth
          value={companyData.name}
          onChange={handleChange}
          disabled={mode === 'edit' && !selectedCompanyId}
        />
        <TextField
          margin="dense"
          name="phoneNumber"
          label="Phone Number"
          type="tel"
          fullWidth
          value={companyData.phoneNumber}
          onChange={handleChange}
          disabled={mode === 'edit' && !selectedCompanyId}
        />
        <TextField
          margin="dense"
          name="preauthPhoneNumber"
          label="Pre-authorization Phone Number"
          type="tel"
          fullWidth
          value={companyData.preauthPhoneNumber}
          onChange={handleChange}
          disabled={mode === 'edit' && !selectedCompanyId}
        />
        <TextField
          margin="dense"
          name="costPerEE"
          label="Cost Per Employee"
          type="text"
          fullWidth
          placeholder="e.g., 15 or 10/15/15/20"
          value={companyData.costPerEE}
          onChange={handleChange}
          disabled={mode === 'edit' && !selectedCompanyId}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={isLoading || (mode === 'edit' && !selectedCompanyId)}>
          {isLoading ? 'Processing...' : mode === 'edit' ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ManageCompaniesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  company: PropTypes.shape({
    companyId: PropTypes.number,
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    preauthPhoneNumber: PropTypes.string,
    costPerEE: PropTypes.string
  })
};

export default ManageCompaniesDialog;
