/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Wrapper, CardContainer, CompanyCard, CompanyLink, Title, Content, CompanyList, SearchBar, ErrorMessage } from '../styles/companyComponents';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchTerm, fetchAdminDetails } from '../slices/companySlice';
import { selectCompaniesWithUserCounts, selectSearchTerm, selectHasError, selectErrorMessage, selectIsSuperAdmin } from '../selectors';
import { CardContent, Typography, Box, Tooltip, IconButton, Chip, Button } from '@mui/material';
import { AuthContext } from '../utils/AuthProvider';
import { LogoutButton } from '../styles/buttonComponents';
import MultiCompanyUploadButton from '../components/MultiCompanyUploadButton';
import CompanyManagerButton from '../components/CompanyManagerButton';
import InfoIcon from '@mui/icons-material/Info';
// import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import SuperAdminPanel from './SuperAdminPanel';

const Company = () => {
  const dispatch = useDispatch();
  const { currentUser, signOut } = useContext(AuthContext);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [showSuperAdminPanel, setShowSuperAdminPanel] = useState(false);

  const companies = useSelector(selectCompaniesWithUserCounts);
  const searchTerm = useSelector(selectSearchTerm);
  const hasError = useSelector(selectHasError);
  const errorMessage = useSelector(selectErrorMessage);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  useEffect(() => {
    dispatch(fetchAdminDetails(currentUser.email));
  }, [dispatch, currentUser.email]);

  const filteredCompanies = companies.filter(company =>
    company.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedCompanies = [...filteredCompanies].sort((a, b) => {
    if (sortBy === 'name') {
      return sortOrder === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
    } else if (sortBy === 'totalUsers') {
      const totalA = a.activeUsers + a.disabledUsers;
      const totalB = b.activeUsers + b.disabledUsers;
      return sortOrder === 'asc' ? totalA - totalB : totalB - totalA;
    }
    return 0;
  });

  const handleSort = (newSortBy) => {
    if (newSortBy === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(newSortBy);
      setSortOrder('asc');
    }
  };

  const renderSortIcon = (columnName) => {
    if (sortBy !== columnName) return null;
    return sortOrder === 'asc' ? ' ▲' : ' ▼';
  };

  const CompanyDetailsTooltip = ({ company }) => (
    <Box>
      <Typography variant="body2"><strong>Company ID:</strong> {company.companyId}</Typography>
      <Typography variant="body2"><strong>Name:</strong> {company.name}</Typography>
      <Typography variant="body2"><strong>Phone Number:</strong> {company.phoneNumber}</Typography>
      <Typography variant="body2"><strong>Pre-auth Phone Number:</strong> {company.preauthPhoneNumber}</Typography>
    </Box>
  );

  return (
    <Wrapper>
      <Content>
        <Title variant="h4" component="h1" gutterBottom>
          Select Company
        </Title>
        <LogoutButton variant="contained" color="secondary" onClick={signOut}>
          Logout
        </LogoutButton>
        <Box display="flex" flexDirection="column" paddingBottom={2} gap={2}>
          <CompanyManagerButton />
          <MultiCompanyUploadButton />
          {isSuperAdmin && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowSuperAdminPanel(!showSuperAdminPanel)}
            >
              {showSuperAdminPanel ? 'Close Super Admin Panel' : 'Open Super Admin Panel'}
            </Button>
          )}
        </Box>
        {showSuperAdminPanel && isSuperAdmin && <SuperAdminPanel />}
        <SearchBar
          type="text"
          placeholder="Search for a company..."
          value={searchTerm}
          onChange={e => dispatch(setSearchTerm(e.target.value))}
        />
        {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Chip
            label={`Sort by: ${sortBy === 'name' ? 'Company Name' : 'Total Users'}${renderSortIcon(sortBy)}`}
            onClick={() => handleSort(sortBy === 'name' ? 'totalUsers' : 'name')}
            color="primary"
            clickable
          />
        </Box>
        <CompanyList>
          {sortedCompanies.map((company) => {
            const totalUsers = company.activeUsers + company.disabledUsers;
            let costPerEmployeeDisplay;
            let totalRevenue;

            if (company.costPerEmployee === null || company.costPerEmployee === undefined) {
              costPerEmployeeDisplay = 'N/A';
              totalRevenue = 'N/A';
            } else if (typeof company.costPerEmployee === 'number') {
              // Single cost per employee
              costPerEmployeeDisplay = `$${company.costPerEmployee}`;
            } else if (typeof company.costPerEmployee === 'object') {
              const pricingTiers = Object.keys(company.costPerEmployee).length;
              const usersPerTier = totalUsers / pricingTiers;

              totalRevenue = Object.entries(company.costPerEmployee).reduce((total, [coverage, cost]) => {
                return total + cost * usersPerTier;
              }, 0);

              costPerEmployeeDisplay = (
                <ul style={{ paddingLeft: '20px', margin: '0' }}>
                  {Object.entries(company.costPerEmployee).map(([coverage, cost]) => (
                    <li key={coverage}>
                      {coverage}: ${cost}
                    </li>
                  ))}
                </ul>
              );
              totalRevenue = `$${totalRevenue.toFixed(2)}`;
            }

            return (
              <RouterLink
                to={`/company/${company.companyId}`}
                style={{ textDecoration: 'none' }}
                key={company.companyId}
              >
                <CompanyCard>
                  <CardContent>
                    <CardContainer>
                      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <CompanyLink>
                          {company.name}
                        </CompanyLink>
                        <Tooltip title={<CompanyDetailsTooltip company={company} />} arrow>
                          <IconButton size="small">
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={2}>
                        <Box>
                          <Typography variant="body2">
                            Active Users: {company.activeUsers}
                          </Typography>
                          <Typography variant="body2" color="error.main">
                            Disabled Users: {company.disabledUsers}
                          </Typography>
                          <Typography variant="body2" color="info.main">
                            Total Users: {totalUsers}
                          </Typography>
                          <Typography variant="body2" color="info.main">
                            Cost per Employee: {costPerEmployeeDisplay}
                          </Typography>
                          <Typography variant="body2" color="success.dark">
                            Total Revenue: {totalRevenue}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContainer>
                  </CardContent>
                </CompanyCard>
              </RouterLink>
            );
          })}
        </CompanyList>
      </Content>
    </Wrapper>
  );
};

export default Company;
